import { Injectable, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorDescription } from 'src/app/core/domain/error-description.type';
import { ProductInformation } from 'src/app/core/domain/ProductInformation.type';
import { AlkoThing } from 'src/app/core/domain/thing.type';

type Tag = {
  key: string;
  doc_count: number;
};

export type ArticleMinInfo = {
  articleNumber: string;
  title: string;
};

@Injectable({ providedIn: 'root' })
export abstract class IPimInteractor {
  abstract searchProductByArticleNumber(
    articleNumber: string,
  ): Observable<ArticleMinInfo[]>;
  abstract searchProductByArticleTitle(
    title: string,
  ): Observable<ArticleMinInfo[]>;
  abstract getKnowledgeItemsTags(): Observable<Tag[]>;
  abstract getProductInformation(
    articleNumber: string,
  ): Observable<ProductInformation>;
  abstract getProductInformations(
    articleNumbers: string[],
  ): Observable<{ [articleNumber: string]: ProductInformation }>;
  abstract getSparepartsOfProduct(
    articleNumber: string,
  ): Observable<ProductInformation[]>;
  abstract getErrorDescriptions(): Observable<{
    [errorCode: number]: ErrorDescription;
  }>;
  abstract getTranslations(
    translationPool: string,
  ): Observable<{ [key: string]: string }>;
}
